// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
//@ts-ignore
import Cookies from "js-cookie";
//@ts-ignore
import { debounce } from "lodash";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  addOrgContent?: any;
  orgContent?: any;
  history?: any;
  isOnline?: boolean;
  resetFilters?: any;
  saveCoursesToRedux: any;
  appState: any;
  setFilterValue: (data: { name: string; value: any[] | string }) => void;
  setFilterField: (data: { name: string; value: any[] }) => void;
  setLoading: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  filteredDataApiBody: any;
  totalPages: number;
  pageNumber: number;
  responseData: any;
  showSidebar: boolean;
  responseOrganization: any;
  responseCourses: any;
  courseId: any;
  loadOrganization: string;
  loadCourses: string;
  searchString: string;
  setColorOfTIme: any;
  schools: [];
  dec: any;
  resetFilter: boolean;
  isOffline: any;
  doNotCall: boolean;
  autoCompleteFields: string[];
  unFilteredData: any;
  searchStringValue: any;
  downloadCount: any;
  downloadDetails: any;
  downloadingData: any;
  ATOZ: string[];
  afterLoadCourse: boolean;
  adsDataCourseAll:any[];
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class TrainingS1WebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrganizationApiCallId: any;
  getCoursesApiCallId: any;
  getCoursesBySearchApiCallId: string;
  getFilteredApiCallId: string;
  getSchoolsApiCallerId: string;
  getCourseDetailApiCallId: string;
  getAutoCompleteFieldsID: string;
  getDownloadCountApiCallId: string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    // Customizable Area Start
    this.state = {
      adsDataCourseAll:[],
      totalPages: 0,
      pageNumber: 1,
      filteredDataApiBody: {},
      autoCompleteFields: [],
      responseData: [1, 2, 3],
      showSidebar: false,
      responseOrganization: [],
      responseCourses: [],
      courseId: "",
      loadCourses: "notLoaded",
      loadOrganization: "notLoaded",
      searchString: "",
      setColorOfTIme: "",
      schools: [],
      dec: [],
      resetFilter: false,
      isOffline: "",
      doNotCall: true,
      unFilteredData: "All",
      searchStringValue: "",
      downloadCount: "",
      downloadDetails: "",
      downloadingData: "",
      ATOZ: Array.from(Array(26))
        ?.map((e: any, i: number) => i + 65)
        ?.map((x: any) => String.fromCharCode(x)),
      afterLoadCourse: false
    };
    this.getOrganizationApiCallId= "";
    this.getCoursesApiCallId= "";
    this.getCoursesBySearchApiCallId= "";
    this.getFilteredApiCallId="";
    this.getSchoolsApiCallerId= "";
    this.getCourseDetailApiCallId= "";
    this.getAutoCompleteFieldsID= "";
    this.getDownloadCountApiCallId= "";
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // FILTER STATES METHODS

  handleFilterChangeHome = (operation: string, name: string) => {
    const AllFilters = [
      ...this.props.appState.appReducer.TrainingAidsFilterValue,
      ...this.props.appState.appReducer.TrainingAreaFilterValue,
      ...this.props.appState.appReducer.DifficultyFilterValue,
      ...this.props.appState.appReducer.groupTypeFilterValue,
      ...this.props.appState.appReducer.schoolFilterValue,
      ...this.props.appState.appReducer.organizationValues,
      ...this.props.appState.appReducer.durationValues,
    ];
    if (AllFilters.length == 1) {
      this.getallCourses();
    }
    switch (operation) {
      case "Training Area":
        let TrainingAreaFilterFieldsTemp =
          this.props.appState.appReducer.TrainingAreaFilterFields?.map(
            (item: any) =>
              item.name === name
                ? { ...item, isChecked: !item.isChecked }
                : item
          );
        this.props.setFilterField({
          name: "TrainingAreaFilterFields",
          value: TrainingAreaFilterFieldsTemp,
        });
        const tempTrainingAreaFilterValue =
          this.props.appState.appReducer.TrainingAreaFilterValue.filter(
            (item: any) => item !== name
          );
        this.props.setFilterValue({
          name: "TrainingAreaFilterValue",
          value: tempTrainingAreaFilterValue,
        });
        return;

      case "Training Aids":
        let TrainingAidsFilterFieldsTemp =
          this.props.appState.appReducer.TrainingAidsFilterFields?.map(
            (item: any) =>
              item.name === name
                ? { ...item, isChecked: !item.isChecked }
                : item
          );
        this.props.setFilterField({
          name: "TrainingAidsFilterFields",
          value: TrainingAidsFilterFieldsTemp,
        });
        const tempTrainingAidsFilterValue =
          this.props.appState.appReducer.TrainingAidsFilterValue.filter(
            (item: any) => item !== name
          );
        this.props.setFilterValue({
          name: "TrainingAidsFilterValue",
          value: tempTrainingAidsFilterValue,
        });
        return;

      case "Difficulty":
        let DifficultyFilterFieldsTemp =
          this.props.appState.appReducer.DifficultyFilterFields?.map(
            (item: any) =>
              item.name === name
                ? { ...item, isChecked: !item.isChecked }
                : item
          );
        this.props.setFilterField({
          name: "DifficultyFilterFields",
          value: DifficultyFilterFieldsTemp,
        });
        const tempDifficultyFilterValue =
          this.props.appState.appReducer.DifficultyFilterValue.filter(
            (item: any) => item !== name
          );
        this.props.setFilterValue({
          name: "DifficultyFilterValue",
          value: tempDifficultyFilterValue,
        });
        return;

      case "Group Type":
        let groupTypeFilterFieldsTemp =
          this.props.appState.appReducer.groupTypeFilterFields?.map(
            (item: any) =>
              item.name === name
                ? { ...item, isChecked: !item.isChecked }
                : item
          );
        this.props.setFilterField({
          name: "groupTypeFilterFields",
          value: groupTypeFilterFieldsTemp,
        });
        const tempGroupTypeFilterValue =
          this.props.appState.appReducer.groupTypeFilterValue.filter(
            (item: any) => item !== name
          );
        this.props.setFilterValue({
          name: "groupTypeFilterValue",
          value: tempGroupTypeFilterValue,
        });
        return;

      case "Schools":
        let schoolFilterValueTemp =
          this.props.appState.appReducer.schoolFilterFields?.map((item: any) =>
            item.name === name ? { ...item, isChecked: !item.isChecked } : item
          );
        this.props.setFilterField({
          name: "schoolFilterFields",
          value: schoolFilterValueTemp,
        });
        const tempSchoolFilterValue =
          this.props.appState.appReducer.schoolFilterValue.filter(
            (item: any) => item !== name
          );
        this.props.setFilterValue({
          name: "schoolFilterValue",
          value: tempSchoolFilterValue,
        });
        return;

      case "Organizations":

        if (this.props.appState.appReducer.organizationValues.length === 1) {
          let organizationFieldsTemp = this.props.appState.appReducer.organizationFields?.map((item: any) => (item.name === name) ? { ...item, isChecked: !item.isChecked } : (item.name === "All") ? { ...item, isChecked: true } : item);
          this.props.setFilterField({
            name: "organizationFields",
            value: organizationFieldsTemp,
          });
        } else {
          let organizationFieldsTemp = this.props.appState.appReducer.organizationFields?.map((item: any) => (item.name === name) ? { ...item, isChecked: !item.isChecked } : (item.name === "All") ? { ...item, isChecked: false } : item);
          this.props.setFilterField({
            name: "organizationFields",
            value: organizationFieldsTemp,
          });
        }

        const tempOrganizationValues =
          this.props.appState.appReducer.organizationValues.filter(
            (item: any) => item.name !== name
          );
        this.props.setFilterValue({
          name: "organizationValues",
          value: tempOrganizationValues,
        });
        return;

      case "Durations":
        let durationFieldsTemp =
          this.props.appState.appReducer.durationFields?.map((item: any) =>
            item.name === name ? { ...item, isChecked: !item.isChecked } : item
          );
        this.props.setFilterField({
          name: "durationFields",
          value: durationFieldsTemp,
        });
        const tempDurationValues =
          this.props.appState.appReducer.durationValues.filter(
            (item: any) => item !== name
          );
        this.props.setFilterValue({
          name: "durationValues",
          value: tempDurationValues,
        });
        return;

      default:
        return;
    }
  };

  handleSidebar = () => {
    this.setState({
      showSidebar: !this.state.showSidebar,
      doNotCall: false,
    });
  };

  getFilterDataFromSidebar = (
    shortBy?: any,
    schoolId?: any,
    groupType?: any,
    difficulty?: any,
    trainingAids?: any,
    trainingArea?: any,
    durationValues?: [],
    orgIds?: [],
    pageNumber?: number
  ) => {
    const AllFilters = [
      ...this.props.appState.appReducer.TrainingAidsFilterValue,
      ...this.props.appState.appReducer.TrainingAreaFilterValue,
      ...this.props.appState.appReducer.DifficultyFilterValue,
      ...this.props.appState.appReducer.groupTypeFilterValue,
      ...this.props.appState.appReducer.schoolFilterValue,
      ...this.props.appState.appReducer.organizationValues,
      ...this.props.appState.appReducer.durationValues,
    ];
    if (
      AllFilters.length === 0 &&
      this.props.appState.appReducer.sortBy === "(A-Z)"
    ) {
      return;
    }

    let timeSlot = durationValues;
    this.getFilterdCourses(
      orgIds,
      timeSlot,
      shortBy,
      schoolId,
      groupType,
      difficulty,
      trainingAids,
      trainingArea,
      pageNumber
    );
    this.setState({ resetFilter: true });
  };

  setInitialFilterFields() {
    const formattedOrgData =
      this.props.appState.appReducer.responseOrganization?.map(
        (item: { name: string; image_url: string }) =>
          item.name === "All"
            ? { name: item?.name, isChecked: true, image_url: item?.image_url }
            : { name: item?.name, isChecked: false, image_url: item?.image_url }
      );
    const formattedSchoolData = this.props.appState.appReducer.schools?.map(
      (item: { name: string }) => ({ name: item?.name, isChecked: false })
    );
    this.props.setFilterField({
      name: "TrainingAreaFilterFields",
      value: configJSON.TRAINING_AREA_FILTER_FIELDS,
    });
    this.props.setFilterField({
      name: "TrainingAidsFilterFields",
      value: configJSON.TRAINING_AIDS_FILTER_FIELDS,
    });
    this.props.setFilterField({
      name: "DifficultyFilterFields",
      value: configJSON.DIFFICULTY_FILTER_FIELDS,
    });
    this.props.setFilterField({
      name: "groupTypeFilterFields",
      value: configJSON.GROUP_FILTER_FIELDS,
    });
    this.props.setFilterField({
      name: "durationFields",
      value: configJSON.DURATION_FILTER_FIELDS,
    });
    this.props.setFilterField({
      name: "organizationFields",
      value: formattedOrgData,
    });
    this.props.setFilterField({
      name: "schoolFilterFields",
      value: formattedSchoolData,
    });
  }

  shouldSetInitialFields(x?: string) {
    const formattedOrgData =
      this.props.appState.appReducer.responseOrganization?.map(
        (item: { name: string; image_url: string }) =>
          item.name === "All"
            ? { name: item?.name, isChecked: true, image_url: item?.image_url }
            : { name: item?.name, isChecked: false, image_url: item?.image_url }
      );
    const formattedSchoolData = this.props.appState.appReducer.schools?.map(
      (item: { name: string }) => ({ name: item?.name, isChecked: false })
    );
    //@ts-ignore
    const {
      schoolFilterFields,
      organizationFields,
      durationFields,
      groupTypeFilterFields,
      DifficultyFilterFields,
      TrainingAidsFilterFields,
      TrainingAreaFilterFields,
    } = this.props.appState?.appReducer;
    if (
      schoolFilterFields?.length !== formattedSchoolData?.length ||
      organizationFields?.length !== formattedOrgData?.length ||
      durationFields?.length !== configJSON.DURATION_FILTER_FIELDS?.length ||
      groupTypeFilterFields?.length !== configJSON.GROUP_FILTER_FIELDS?.length ||
      DifficultyFilterFields?.length !==
      configJSON.DIFFICULTY_FILTER_FIELDS?.length ||
      TrainingAidsFilterFields?.length !==
      configJSON.TRAINING_AIDS_FILTER_FIELDS?.length ||
      TrainingAreaFilterFields?.length !==
      configJSON.TRAINING_AREA_FILTER_FIELDS?.length
    ) {
      return true;
    }
    return false;
  }

  shouldCallGetAllCourse() {
    //@ts-ignore
    const {
      sortBy,
      schoolFilterValue,
      organizationValues,
      durationValues,
      groupTypeFilterValue,
      DifficultyFilterValue,
      TrainingAidsFilterValue,
      TrainingAreaFilterValue,
    } = this.props.appState.appReducer;
    if (
      (schoolFilterValue.length || 0) > 0 ||
      (organizationValues.length || 0) > 0 ||
      (durationValues.length || 0) > 0 ||
      (groupTypeFilterValue.length || 0) > 0 ||
      (DifficultyFilterValue.length || 0) > 0 ||
      (TrainingAidsFilterValue.length || 0) > 0 ||
      (TrainingAreaFilterValue.length || 0) > 0
    ) {
      return false;
    }
    return true;
  }
  getfilterdataEXT() {
    const {
      sortBy,
      schoolFilterValue,
      organizationValues,
      durationValues,
      groupTypeFilterValue,
      DifficultyFilterValue,
      TrainingAidsFilterValue,
      TrainingAreaFilterValue,
    } = this.props.appState.appReducer;

    const schoolIds: string[] = [];
    const orgIds: string[] = [];
    for (let school of schoolFilterValue) {
      let tempSchool: { name: string; id: string } | undefined =
        this.props.appState.appReducer.schools.find(
          (item: { name: string; id: string }) => item.name === school
        );
      //@ts-ignore
      tempSchool !== undefined && schoolIds.push(tempSchool.id);
    }
    for (let org of organizationValues) {
      let tempOrg: { name: string; id: string } | undefined =
        this.props.appState.appReducer.responseOrganization.find(
          (item: { name: string; id: string }) => item.name === org.name
        );
      //@ts-ignore
      tempOrg !== undefined && orgIds.push(tempOrg.id);
    }
    this.getFilterdCourses(
      orgIds,
      durationValues,
      sortBy,
      schoolIds,
      groupTypeFilterValue,
      DifficultyFilterValue,
      TrainingAidsFilterValue,
      TrainingAreaFilterValue,
      this.state.pageNumber
    );
  }
  OfflineWeb=()=>{
    Cookies.set("isOnline",false)
    this.setState({isOffline:false})
  }
  OnlineWeb=()=>{
    Cookies.set("isOnline",true)
    this.setState({isOffline:true})
  }
  CheckOnlineOrOfllineWeb=()=>{
    if (!window.navigator.onLine) {
      this.setState({ loadCourses: "notLoaded" });
      this.OfflineWeb()
      const coursesArray = JSON.parse(
        (localStorage.getItem("allCourses") as any) || "[]"
      );
      if (coursesArray?.length !== 0) {
        coursesArray?.forEach((courseLocalStorage: any) => {
          courseLocalStorage["offline_downloaded"] = true;
        });
      }
      if (coursesArray.length === 0) {
        this.setState({ loadCourses: "no data there" });
      }
      this.props?.setLoading(false)
      this.setState({ responseCourses: coursesArray });
    } else {
      this.OnlineWeb()
      this.setState({ unFilteredData: "All", loadCourses: "notLoaded" });
      this.props?.setLoading(true)
      if (this.props.appState.appReducer.organizationFields.length === 0) {
        this.getOrganization();
      }
      if (this.props.appState.appReducer.schoolFilterFields.length === 0) {
        this.getSchoolsByid();
      }
      if (this.shouldCallGetAllCourse()) {
        this.getallCourses();
      } else {
        this.getfilterdataEXT();
      }
      
    }
  }

  async componentDidMount() {
    if (this.shouldSetInitialFields()) {
      this.setInitialFilterFields();
    }
    this.CheckOnlineOrOfllineWeb()
  }

  checkUpdateMethodToBeOnlineOrOffline=(prevState:any)=>{
    if (!window.navigator.onLine) {
      this.OfflineWeb()
     } else {
       this.setState({ responseCourses: [], loadCourses: "notLoaded" });
       this.OnlineWeb()
       this.setState({ loadCourses: "notLoaded" });
       if (this.props.appState.appReducer.organizationFields.length === 0) {
         this.getOrganization();
       }
       if (this.props.appState.appReducer.schoolFilterFields.length === 0) {
         this.getSchoolsByid();
       }
       if (prevState.responseCourses !== this.state.responseCourses) {
         if (this.shouldCallGetAllCourse()) {
           this.getallCourses();
         }
       }
       
     }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.shouldSetInitialFields()) {
      this.setInitialFilterFields();
    }

    // debugger
    if (prevState.isOffline !== this.state.isOffline) {
      this.checkUpdateMethodToBeOnlineOrOffline(prevState)
      window.addEventListener("offline", () => {
        console.log("offline");
        this.OfflineWeb();
        this.setState({ loadCourses: "notLoaded" });
        this.props?.setLoading(false)

        if (this.shouldCallGetAllCourse()) {
          const coursesArray = JSON.parse(
            (localStorage.getItem("allCourses") as any) || "[]"
          );
          if (coursesArray?.length !== 0) {
            coursesArray?.forEach((courseLocalStorage: any) => {
              courseLocalStorage["offline_downloaded"] = true;
            });
            if (coursesArray.length === 0) {
              this.setState({ loadCourses: "no data there" });
            }
          }
          const schoolIds: string[] = [];
          const orgIds: string[] = [];

          for (let school of this.props.appState.appReducer?.schoolFilterValue) {
            let tempSchool: { name: string, id: string } | undefined = this.props.appState.appReducer?.schools.find((item: { name: string, id: string }) => item.name === school);
            //@ts-ignore
            tempSchool !== undefined && schoolIds.push(tempSchool.id);
          }
          for (let org of this.props.appState.appReducer?.organizationValues) {
            let tempOrg: { name: string, id: string } | undefined = this.props.appState.appReducer?.responseOrganization.find((item: { name: string, id: string }) => item.name === org.name);
            //@ts-ignore
            tempOrg !== undefined && orgIds.push(tempOrg.id);
          }
          const downloadedCourses = JSON.parse(localStorage.getItem("allCourses") || "[]");
          const training_area_filter = this.props.appState.appReducer?.TrainingAreaFilterValue.length > 0 ? downloadedCourses.filter((course: any) => this.props.appState.appReducer?.TrainingAreaFilterValue.includes(course.training_area)) : downloadedCourses;
          const training_aids_filter = this.props.appState.appReducer?.TrainingAidsFilterValue.length > 0 ? training_area_filter.filter((course: any) => this.props.appState.appReducer?.TrainingAidsFilterValue.includes(course.training_aids)) : training_area_filter;
          const organization_id_filter = orgIds.length > 0 ? training_aids_filter.filter((course: any) => {
            let temp = false;
            orgIds.forEach((item) => {
              if (course.c_org_ids.includes(item)) {
                temp = true;
              }
            })
            return temp;
          }) : training_aids_filter;
          const group_type_filter = this.props.appState.appReducer?.groupTypeFilterValue.length > 0 ? organization_id_filter.filter((course: any) => this.props.appState.appReducer?.groupTypeFilterValue.includes(course.group_type)) : organization_id_filter;
          const duration_filter = this.props.appState.appReducer.durationValues.length > 0 ? group_type_filter.filter((course: any) => this.props.appState.appReducer?.durationValues.includes(course.duration)) : group_type_filter;
          const difficulty_level_filter = this.props.appState.appReducer?.DifficultyFilterValue.length > 0 ? duration_filter.filter((course: any) => this.props.appState.appReducer?.DifficultyFilterValue.includes(course.difficulty_level)) : duration_filter;
          const school_id_filter = schoolIds.length > 0 ? difficulty_level_filter.filter((course: any) => {
            let ans = false;
            if (course.school_id) {
              for (let x of course.school_id) {
                if (schoolIds.includes(x)) {
                  ans = true;
                }
              }
            }
            return ans;
          }
          ) : difficulty_level_filter;
          const description = school_id_filter?.map((item: any) => item.title)
          let sortBy = description;
          // let sortBy = school_id_filter.sort((a: any, b: any) => a.title.localeCompare(b.title));
          if (this.props.appState.appReducer.sortBy === "(A-Z)") {
            sortBy = school_id_filter.sort((a: any, b: any) => a.title.localeCompare(b.title));
          } else if (this.props.appState.appReducer.sortBy === "By Download") {
            sortBy = school_id_filter.sort((a: any, b: any) => b.download_count - a.download_count).sort((a: any, b: any) => a.title.localeCompare(b.title));
          } else if (this.props.appState.appReducer.sortBy === "Newly Added") {
            //@ts-ignore
            sortBy = school_id_filter.sort((a: any, b: any) => new Date(b.created_at) - new Date(a.created_at));
          }

          this.setState({ responseCourses: coursesArray }, () => this.filterCoursesOffline(school_id_filter, sortBy));
        } else {
          const schoolIds: string[] = [];
          const orgIds: string[] = [];

          for (let school of this.props.appState.appReducer
            .schoolFilterValue) {
            let tempSchool: { name: string; id: string } | undefined =
              this.props.appState.appReducer.schools.find(
                (item: { name: string; id: string }) => item.name === school
              );
            //@ts-ignore
            tempSchool !== undefined && schoolIds.push(tempSchool.id);
          }
          for (let org of this.props.appState.appReducer.organizationValues) {
            let tempOrg: { name: string; id: string } | undefined =
              this.props.appState.appReducer.responseOrganization.find(
                (item: { name: string; id: string }) => item.name === org.name
              );
            //@ts-ignore
            tempOrg !== undefined && orgIds.push(tempOrg.id);
          }

          const downloadedCourses = JSON.parse(
            localStorage.getItem("allCourses") || "[]"
          );
          const training_area_filter =
            this.props.appState.appReducer?.TrainingAreaFilterValue.length > 0
              ? downloadedCourses.filter((course: any) =>
                this.props.appState.appReducer?.TrainingAreaFilterValue.includes(
                  course.training_area
                )
              )
              : downloadedCourses;
          const training_aids_filter =
            this.props.appState.appReducer?.TrainingAidsFilterValue.length > 0
              ? training_area_filter.filter((course: any) =>
                this.props.appState.appReducer?.TrainingAidsFilterValue.includes(
                  course.training_aids
                )
              )
              : training_area_filter;
          const organization_id_filter =
            orgIds.length > 0
              ? training_aids_filter.filter((course: any) =>
                orgIds.includes(course.organization_id)
              )
              : training_aids_filter;
          const group_type_filter =
            this.props.appState.appReducer?.groupTypeFilterValue.length > 0
              ? organization_id_filter.filter((course: any) =>
                this.props.appState.appReducer?.groupTypeFilterValue.includes(
                  course.group_type
                )
              )
              : organization_id_filter;
          const duration_filter =
            this.props.appState.appReducer.durationValues.length > 0
              ? group_type_filter.filter((course: any) =>
                this.props.appState.appReducer?.durationValues.includes(
                  course.duration
                )
              )
              : group_type_filter;
          const difficulty_level_filter =
            this.props.appState.appReducer?.DifficultyFilterValue.length > 0
              ? duration_filter.filter((course: any) =>
                this.props.appState.appReducer?.DifficultyFilterValue.includes(
                  course.difficulty_level
                )
              )
              : duration_filter;
          const school_id_filter =
            schoolIds.length > 0
              ? difficulty_level_filter.filter((course: any) => {
                let ans = false;
                for (let x of course.school_id) {
                  if (schoolIds.includes(x)) {
                    ans = true;
                  }
                }
                return ans;
              })
              : difficulty_level_filter;

          const description = school_id_filter?.map((item: any) => item.title);
          this.filterCoursesOffline(school_id_filter, description);
        }

        
      });

      window.addEventListener("online", (event: any) => {
        //Need to addd code of filterdata.
        this.setState({ pageNumber: 1 })
        this.OnlineWeb()
        location.reload()
        if (this.shouldCallGetAllCourse() && (this.props.appState.appReducer.sortBy == "(A-Z)" || this.props.appState.appReducer.sortBy == "")) {
          this.getallCourses();
        } else {
          this.getfilterdataEXT()
        }
        if (this.props.appState.appReducer.schoolFilterFields.length === 0) {
          this.getSchoolsByid();
        }
        if (this.props.appState.appReducer.organizationFields.length === 0) {
          this.getOrganization();
        }
        
        event.stopImmediatePropagation();
      });
    }

    if (prevState.courseId !== this.state.courseId) {
      this.state.courseId === "All"
        ? this.getSchoolsByid()
        : this.getSchoolsByid(this.state.courseId);
    }
  }

  makeDownloadCOunt = (courseId: any, isDOwnload: boolean): boolean => {
    this.setState({
      downloadDetails: isDOwnload,
      downloadCount: courseId,
      downloadingData: courseId.id,
    });
    const header = {
      "Content-Type": configJSON.searchCoursesApiContentType,
    };

    const httpBody = {
      course: {
        id: courseId.id,
        is_download: isDOwnload,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDownloadCountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postDownloadCount
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDownloadFunction = async (course?: any, responseJson?: any) => {
    const coursesArray = JSON.parse(
      (localStorage.getItem("allCourses") as any) || "[]"
    );
    if (!coursesArray?.some((e: any) => e?.id === responseJson?.course?.id)) {
      coursesArray.push(responseJson?.course);
    }
    localStorage.setItem("allCourses", JSON.stringify(coursesArray));
    this.getCourseDetails(course?.id);

    if (this.state.unFilteredData == "All") {
      const updatedData = this.state.responseCourses?.map(
        (Item: any, index: number) => {
          if (Item.id === responseJson?.course?.id) {
            return {
              ...Item,
              offline_downloaded: true,
            };
          } else {
            return Item;
          }
        }
      );
      this.setState({ responseCourses: updatedData });
    } else {
      if (this.state.searchStringValue) {
        this.getSearchedCourses(this.state.searchStringValue);
        return;
      }
      const updatedData = this.state.responseCourses?.map(
        (Item: any, index: number) => {
          if (Item.id === responseJson?.course?.id) {
            return {
              ...Item,
              offline_downloaded: true,
            };
          } else {
            return Item;
          }
        }
      );
      this.setState({ responseCourses: updatedData });
    }
  };

  filterCoursesOffline = (courses: any[], descriptionList: string[]) => {
    courses?.forEach((courseLocalStorage: any) => {
      courseLocalStorage["offline_downloaded"] = true;
    });
    this.setState({
      responseCourses: courses,
      dec: descriptionList,
    });
  };

  searchCoursesOffline = (value: string) => {
    if (value.length >= 2) {

      let shouldMatchTag = (item: any) => {
        let ans = false;
        item.tag_list?.map((tag: string) => {
          if (tag.toLowerCase().includes(value.toLowerCase())){
            ans = true;
          }
        });
        return ans;
      }

      let filterCourses = this.state.responseCourses.filter((item: any) => item.title.toLowerCase().includes(value.toLowerCase()) || shouldMatchTag(item));
      const description = filterCourses?.map((item: any) => item.title);
      filterCourses?.forEach((courseLocalStorage: any) => {
        courseLocalStorage["offline_downloaded"] = true;
      });
      this.setState({
        responseCourses: filterCourses,
        dec: description,
      });
    }
    if (value === "") {
      const downloadedCourses = JSON.parse(
        localStorage.getItem("allCourses") || "[]"
      );
      const description = downloadedCourses?.map((item: any) => item.title);
      downloadedCourses?.forEach((courseLocalStorage: any) => {
        courseLocalStorage["offline_downloaded"] = true;
      });
      this.setState({
        responseCourses: downloadedCourses,
        dec: description,
      });
    }
  };

  handleDeleteDownloadFunction = async (course?: any, responseJson?: any) => {
    let coursesArray = JSON.parse(
      (localStorage.getItem("allCourses") as any) || "[]"
    );
    coursesArray = coursesArray.filter((item: any) => item.id !== course?.id);
    localStorage.setItem("allCourses", JSON.stringify(coursesArray));
    // this.getCourseDetails(course?.id)
    let dataOfCourses = JSON.parse(
      (localStorage.getItem("courseDataArray") as any) || "[]"
    );
    dataOfCourses = dataOfCourses.filter((item: any) => item.id !== course?.id);
    localStorage.setItem("courseDataArray", JSON.stringify(dataOfCourses));
    if (this.state.unFilteredData == "All") {
      const updatedData = this.state.responseCourses?.map(
        (Item: any, index: number) => {
          if (Item.id === responseJson?.course?.id) {
            return {
              ...Item,
              offline_downloaded: false,
            };
          } else {
            return Item;
          }
        }
      );
      this.setState({ responseCourses: updatedData });
    } else {
      if (this.state.searchStringValue) {
        this.getSearchedCourses(this.state.searchStringValue);
        return;
      }
      const updatedData = this.state.responseCourses?.map(
        (Item: any, index: number) => {
          if (Item.id === responseJson?.course?.id) {
            return {
              ...Item,
              offline_downloaded: false,
            };
          } else {
            return Item;
          }
        }
      );
      this.setState({ responseCourses: updatedData });
    }
  };

  //Getting organization data
  getOrganization = (): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrganizationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.organiztionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCourseDetails = (id: number): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCourseDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCourseDataAPiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCourseDataAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //set searchString
  setSearchString = (value: string) => {
    this.setState({ resetFilter: true });
    if (value !== "") {
      this.setState({ searchStringValue: value });
      this.getSearchedCourses(value);
    } else {
      this.setState({ searchStringValue: "" });
    }
  };

  debounceLogfrd = debounce((value: any) => {
    if (value) {
      this.setState({ searchString: value });
      this.getSearchedCourses(value);
    } else {
      this.getallCourses();
    }
  }, 1000);

  //getting courses
  getallCourses = (pageNumber?: number): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCoursesApiCallId = requestMessage.messageId;

    if (pageNumber) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.coursesEndPoint + "?page=" + pageNumber
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.coursesEndPoint + "?page=" + 1
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  //find position
  findPosition(obj: any) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return [currenttop];
    }
  }

  //getting filteres courses
  getFilterdCourses = (
    orgId?: any,
    timeSlot?: any,
    shortBy?: any,
    schoolId?: any,
    groupType?: any,
    difficulty?: any,
    trainingAids?: any,
    trainingArea?: any,
    pageNumber?: number,
    cachedData?: any,
    FromInfinitesCroll?: string
  ): boolean => {
    if (
      orgId.length === 0 &&
      timeSlot.length === 0 &&
      schoolId.length === 0 &&
      !shortBy &&
      groupType.length === 0 &&
      difficulty.length === 0 &&
      trainingAids.length === 0 &&
      trainingArea.length === 0
    ) {
      if (!this.state.searchStringValue && pageNumber) {
        this.getallCourses();
      } else if (this.state.searchStringValue) {
        this.setState({ unFilteredData: "notAll" });
        return true;
      }
      this.setState({ unFilteredData: "All" });
      return true;
    } else {
      this.setState({ unFilteredData: "notAll" });
      if (this.state.pageNumber == 1) {
        this.props?.setLoading(true)
      }
      const header = {
        "Content-Type": configJSON.searchCoursesApiContentType,
      };

      const data = {} as any;
      orgId.length !== 0 ? (data["c_org_ids"] = orgId) : "";
      timeSlot.length !== 0 ? (data["duration"] = timeSlot) : "";
      shortBy ? (data["sort_by"] = shortBy) : "";
      schoolId.length !== 0 ? (data["school_id"] = schoolId) : "";
      groupType.length !== 0 ? (data["group_type"] = groupType) : "";
      difficulty.length !== 0 ? (data["difficulty"] = difficulty) : "";
      trainingAids.length !== 0 ? (data["training_aids"] = trainingAids) : "";
      trainingArea.length !== 0 ? (data["training_area"] = trainingArea) : "";

      let httpBody = {
        filters: data,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getFilteredApiCallId = requestMessage.messageId;

      if (pageNumber) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.filterEndPoint + `?page=${pageNumber}`
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.filterEndPoint
        );
        this.setState({
          filteredDataApiBody: data,
        });
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  //getting searched courses
  getSearchedCourses = (searchString: string, pageNumber?: number): boolean => {
    if (searchString.length >= 1) {
      this.props?.setLoading(true)
      const header = {
        "Content-Type": configJSON.searchCoursesApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getCoursesBySearchApiCallId = requestMessage.messageId;

      if (pageNumber) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.searchCoursesApiEndPoint +
          searchString +
          `&page=${pageNumber}`
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.searchCoursesApiEndPoint + searchString + `&page=1`
        );
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.searchCoursesApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      return false;
    }
  };

  //getting searched courses
  getAutoCompleteFieldsData = (searchString: string): boolean => {
    if (searchString.length >= 1) {
      const header = {
        "Content-Type": configJSON.getAutoCompleteFieldsApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getAutoCompleteFieldsID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAutoCompleteFieldsApiEndPoint + searchString
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAutoCompleteFieldsApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      return false;
    }
  };

  //getting Schools by Id
  getSchoolsByid = (id?: number | number | string[]): boolean => {
    const header = {
      "Content-Type": configJSON.getSchoolApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSchoolsApiCallerId = requestMessage.messageId;

    let url;
    if (id) {
      if (typeof id == "number" || id == undefined) {
        url =
          configJSON.getSchoolByidApiEndPoint +
          `/organization_schools?org_ids=` +
          [id];
      } else {
        //@ts-ignore
        let temp = "[" + id.toString() + "]";
        //@ts-ignore
        url =
          configJSON.getSchoolByidApiEndPoint +
          `/organization_schools?org_ids=${temp}`;
      }
    }
    id
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getSchoolApiEndPoint
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSchoolApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  cleareautoCompleteFields = () => {
    this.setState({
      autoCompleteFields: [],
    });
  };

  // Customizable Area Start

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          // Search course api response
          if (
            apiRequestCallId === this.getCoursesBySearchApiCallId &&
            responseJson !== undefined
          ) {
            const coursesArray = JSON.parse(
              (localStorage.getItem("allCourses") as any) || "[]"
            );
            if (coursesArray?.length !== 0) {
              responseJson?.courses.forEach((coursesLists: any) => {
                coursesArray?.forEach((courseLocalStorage: any) => {
                  if (coursesLists.id == courseLocalStorage.id) {
                    coursesLists["offline_downloaded"] = true;
                  }
                });
              });
            }

            if (responseJson.page !== "1") {
              this.setState({
                responseCourses: [
                  ...this.state.responseCourses,
                  ...responseJson?.courses,
                ],
                totalPages: responseJson?.total_pages,
              });
            } else {
              this.setState({
                responseCourses: responseJson?.courses,
                totalPages: responseJson?.total_pages,
                pageNumber: 1,
              });
            }
            if (responseJson?.courses?.length !== 0) {
              this.setState({ loadCourses: "loaded" });
              this.props?.setLoading(false)
            } else {
              this.setState({ loadCourses: "noData" });
              this.setState({ afterLoadCourse: true });
              this.props?.setLoading(false)
            }
          }

          // Schools Api response
          if (
            apiRequestCallId === this.getSchoolsApiCallerId &&
            responseJson !== undefined
          ) {
            this.props.setFilterField({
              name: "schools",
              value: responseJson.school,
            });
          }

          // Autocomplete filter Data response
          if (
            apiRequestCallId === this.getAutoCompleteFieldsID &&
            responseJson !== undefined
          ) {
            this.setState({
              autoCompleteFields: responseJson.courses,
            });
          }
          if (
            apiRequestCallId === this.getDownloadCountApiCallId &&
            responseJson !== undefined
          ) {
            if (this.state.downloadDetails) {
              this.handleDownloadFunction(
                this.state.downloadCount,
                responseJson
              );
              this.setState({
                downloadDetails: "",
                downloadCount: "",
                downloadingData: "",
              });
            } else {
              this.handleDeleteDownloadFunction(
                this.state.downloadCount,
                responseJson
              );
              this.setState({
                downloadDetails: "",
                downloadCount: "",
                downloadingData: "",
              });
            }
          }
          if (
            apiRequestCallId === this.getCourseDetailApiCallId &&
            responseJson !== undefined
          ) {
            const dataOfCourses = JSON.parse(
              (localStorage.getItem("courseDataArray") as any) || "[]"
            );
            if (
              !dataOfCourses?.some(
                (e: any) => e?.id === responseJson?.course?.id
              )
            ) {
              dataOfCourses.push({
                courseContent: responseJson?.course?.content,
                id: responseJson?.course?.id,
              });
            }
            localStorage.setItem(
              "courseDataArray",
              JSON.stringify(dataOfCourses)
            );
          }
          // Filter course Api response
          if (
            apiRequestCallId === this.getFilteredApiCallId &&
            responseJson !== undefined
          ) {

            const coursesArray = JSON.parse(
              (localStorage.getItem("allCourses") as any) || "[]"
            );
            if (coursesArray?.length !== 0) {
              responseJson?.courses.forEach((coursesLists: any) => {
                coursesArray?.forEach((courseLocalStorage: any) => {
                  if (coursesLists.id == courseLocalStorage.id) {
                    coursesLists["offline_downloaded"] = true;
                  }
                });
              });
            }
            if (responseJson?.courses?.length === 0) {
              this.setState({ loadCourses: "noData" });
              this.setState({ afterLoadCourse: true });
            }
            this.props?.setLoading(false)
            if (responseJson.page.toString() !== "1") {
              this.setState({
                responseCourses: [
                  ...this.state.responseCourses,
                  ...responseJson?.courses,
                ],
                totalPages: responseJson?.total_pages,
              });
            } else {
              this.setState({
                responseCourses: responseJson?.courses,
                totalPages: responseJson?.total_pages,
                pageNumber: 1,
              });
            }
          }
          if (
            apiRequestCallId === this.getOrganizationApiCallId &&
            responseJson !== undefined
          ) {
            if (this.props?.addOrgContent)
              this.props?.addOrgContent(responseJson?.organizations);
            this.props.setFilterField({
              name: "responseOrganization",
              value: responseJson?.organizations,
            });
            if (responseJson?.organizations?.length !== 0) {
              this.setState({ loadOrganization: "loaded" });
            } else {
              this.setState({ loadOrganization: "noData" });
            }
          }
          if (
            apiRequestCallId === this.getCoursesApiCallId &&
            responseJson !== undefined
          ) {
            if (!window.navigator.onLine) {
              Cookies.set("isOnline", false);
              const coursesArray = JSON.parse(
                (localStorage.getItem("allCourses") as any) || "[]"
              );
              if (coursesArray?.length !== 0) {
                coursesArray?.forEach((courseLocalStorage: any) => {
                  courseLocalStorage["offline_downloaded"] = true;
                });
              }
              if (coursesArray.length === 0) {
                this.setState({ loadCourses: "no data there" });
                this.setState({ afterLoadCourse: true });
              }
              this.props?.setLoading(false)
              this.setState({ responseCourses: coursesArray });
              this.setState({ isOffline: false });
            } else {
              this.props.saveCoursesToRedux(responseJson?.courses);
              const coursesArray = JSON.parse(
                (localStorage.getItem("allCourses") as any) || "[]"
              );
              if (coursesArray?.length !== 0) {
                responseJson?.courses.forEach((coursesLists: any) => {
                  coursesArray?.forEach((courseLocalStorage: any) => {
                    if (coursesLists.id == courseLocalStorage.id) {
                      coursesLists["offline_downloaded"] = true;
                    }
                  });
                });
              }
              if (responseJson.page !== "1") {
                this.setState({
                  responseCourses: [
                    ...this.state.responseCourses,
                    ...responseJson?.courses,
                  ],
                  totalPages: responseJson?.total_pages,
                });
              } else {
                this.setState({
                  adsDataCourseAll:responseJson.ads_data,
                  responseCourses: responseJson?.courses,
                  totalPages: responseJson?.total_pages,
                  pageNumber: 1,
                });
              }
              if (responseJson?.courses?.length !== 0) {
                this.setState({ loadCourses: "loaded" });
                this.props?.setLoading(false)
              } else {
                this.props?.setLoading(false)
                this.setState({ loadCourses: "noData" });
                this.setState({ afterLoadCourse: true });
              }
            }
          }
        }
      } else {
        const errors = responseJson?.errors;
        if (apiRequestCallId === this.getOrganizationApiCallId) {
          this.setState({
            loadOrganization: "noData",
            responseCourses: [],
            dec: [],
          });
        }
        if (apiRequestCallId === this.getCoursesApiCallId) {
          if (window.navigator.onLine) {
            this.setState({
              loadCourses: "noData",
              responseCourses: [],
              dec: [],
            });
            this.props?.setLoading(false)
            this.setState({ afterLoadCourse: true });
          }
        }
        if (apiRequestCallId === this.getDownloadCountApiCallId) {
          this.setState({ downloadingData: "" });
        }
        if (responseJson?.errors === "Courses Not Found.") {
          this.setState({
            loadCourses: "noData",
            responseCourses: [],
            dec: [],
          });
        } else {
          // toast.error("Error occurred.",{position: toast.POSITION.BOTTOM_RIGHT})
        }
      }
    }
    // Customizable Area End
  }
}
// Customizable Area End
// Customizable Area End
