// Customizable Area Start
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card,CardActionArea, CardMedia} from "@material-ui/core";
import { Link } from 'react-router-dom';
const useStyles = makeStyles({
  media: {
    height: 140,
  },
});
interface Props {
  // Customizable Area Start
  banner: string;
  // Customizable Area End
}
const ContactUsAds: React.FC<Props> = ({banner})=> {
  const classes = useStyles();

  return (
    <Link to={"/Contact"}>
    <Card style={{margin:"30px"}}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={banner}
          // image="https://nebulacloud.ai/assets/room/contact-us-banner-9b1204950d03aa3bd00cf7deca317ce5862ddec6ffa25719b50be9c0e605cbf1.jpg"
          title="contact us"
        />
      </CardActionArea>
    </Card>
    </Link>
  );
}

export default ContactUsAds
// Customizable Area End
