import React from 'react';
import CustomAds from './CustomAds.web';
import GoogleAdsenseIntegration from '../../blocks/GoogleAdsenseIntegration/src/GoogleAdsenseIntegration';
import ContactUsAds from './ContactUsAds';
import { makeStyles } from '@material-ui/core/styles';
import { Card,CardActionArea, CardMedia} from "@material-ui/core";
import Link from '@material-ui/core/Link';
import { CustomAdsCounts } from './CustomAdsCounts';
export const configJSON = require("../../../packages/framework/src/config");
const useStyles = makeStyles({
  media: {
    height: 150,
  },
});
interface AdSolutionProps {
  adsList: [{
    ad_type:string,
    custom_ad?:{
        name:string;
        url:string;
        ad_image_url:string;
    },
    contact_us_ad?:{
        name:string;
        image_url:string;
    },
  }]; 
}
const CourseDeatailsAds: React.FC<AdSolutionProps> = ({adsList}) => {
  const classes = useStyles();
  const handleClickDetail = () => {
    CustomAdsCounts();
  };
  if (adsList[0]?.ad_type === "Custom Ad") {
   return <div style={{padding:"15px"}}><Link onClick={handleClickDetail} target="_blank" href={adsList.length>0 && adsList[0]?.custom_ad ? adsList[0]?.custom_ad?.url :""} rel="noreferrer">
   <Card >
     <CardActionArea>
       <CardMedia
         className={classes.media}
         image={adsList.length>0 && adsList[0]?.custom_ad ? adsList[0]?.custom_ad?.ad_image_url:""}
         title={adsList.length>0 && adsList[0]?.custom_ad ? adsList[0]?.custom_ad?.name:""}
       />
     </CardActionArea>  
   </Card>
   </Link></div>;
  } else if (adsList[0]?.ad_type === "Google Ad") {
    return <div style={{padding:"15px"}}><GoogleAdsenseIntegration navigation={undefined} id={""} /></div>
  } else if (adsList[0]?.ad_type === "Contact Us Ad") {
    return <div style={{padding:"15px"}}><ContactUsAds banner={adsList.length>0 && adsList[0]?.contact_us_ad ? adsList[0]?.contact_us_ad?.image_url : ""}/></div>
  }
  return null;
};

export default CourseDeatailsAds;
