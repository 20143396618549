// import baseURLNew from "../../framework/src/config";

export const configJSONBase = require("../../../packages/framework/src/config");

export const CustomAdsCounts = async (): Promise<boolean> => {
    try {
      const formdata = new FormData();
      formdata.append("ad_click", "true");
  
      const requestOptions: RequestInit = {
        method: "PUT",
        body: formdata,
        redirect: "follow"
      };
  
      const response = await fetch(`${configJSONBase.baseURL}/bx_block_custom_ads_count/ad_count`, requestOptions);
      const result = await response.text();
      console.log("count",result); 
  
      return true;
    } catch (error) {
      console.error(error);
      return false; // Return false in case of error
    }
  };
  