import * as React from 'react'
//@ts-ignore
import { Grid } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import StoreProducts from "./StoreProducts.web";
import CustomAds from './CustomAds.web';
import GoogleAdsenseIntegration from '../../blocks/GoogleAdsenseIntegration/src/GoogleAdsenseIntegration.web';
import AdsSolution from './AdsSolution';
export const db = require("../../blocks/filteritems/src/db.json");

interface Props {
    // Customizable Area Start
    products: any[]
    totalPages?: number
    page?: number
    pageChange?: any
    affiliate_count?: any
    adsData?:any[]
    // Customizable Area End
}

const ProductList: React.FC<Props> = ({ products = [], totalPages=0, page=1, pageChange, affiliate_count,adsData }) => {
 const [dataInterval,setDataInterval]=React.useState(0)
 const [adsList,setAdList]=React.useState<string[]>([]);
 const [priorityAds,setPriorityAds]=React.useState<string[]>([]);
 const [customAdIdList,setcustomAdIdList]=React.useState<any[]>([]);
 const [contactUsBanner,setcontactUsBanner]=React.useState<string>("https://nebulacloud.ai/assets/room/contact-us-banner-9b1204950d03aa3bd00cf7deca317ce5862ddec6ffa25719b50be9c0e605cbf1.jpg");
 const customAdIdListTempo=[
{
    name: "test_3",
    url: "www.test1.com",
    ad_image_url: "https://www.wordstream.com/wp-content/uploads/2021/07/banner-ads-examples-ncino.jpg"
}
]
const handlePriority=(itemNo:any)=>{
  if(itemNo==="1"){
    const countOfGoogleAds = adsList.filter((item) => item === "googleAds").length;
    for (let i = 0; i < countOfGoogleAds; i++) {
      priorityAds.push("googleAds");
    }
  }else if(itemNo==="2"){
    const countOfCustomAds = adsList.filter((item) => item === "customAds").length;
    for (let i = 0; i < countOfCustomAds; i++) {
      priorityAds.push("customAds");
    }
  }else if(itemNo==="3"){
    const countOfThree = adsList.filter((item) => item === "contactUsAds").length;
    for (let i = 0; i < countOfThree; i++) {
      priorityAds.push("contactUsAds");
    }}
}
  const handleAds = () => {

    const splitRatio :Array<any>= adsData && adsData.length>0 && adsData[0].ratio.split(":");
    setDataInterval(adsData && adsData.length>0 && adsData[0].web_ad_display_interval)
    setcustomAdIdList(adsData && adsData.length>0 && adsData[0].custom_ads);
    setcontactUsBanner(adsData && adsData.length>0 && adsData[0].contact_us_ads.length>0 ?adsData[0].contact_us_ads[0]?.image_url : "https://nebulacloud.ai/assets/room/contact-us-banner-9b1204950d03aa3bd00cf7deca317ce5862ddec6ffa25719b50be9c0e605cbf1.jpg");
    splitRatio?.map((itemNo:any,index:number)=>{
      if(index===0){
        for (let gAds: number = 0; gAds < +itemNo; gAds++){
          adsList.push("googleAds")
      }
      }
      if(index===1){
        for (let cAds: number = 0; cAds < +itemNo; cAds++){
          adsList.push("customAds")
        }
      }
      if(index===2){
        for (let coAds: number = 0; coAds < +itemNo; coAds++){
            adsList.push("contactUsAds")
        }
      }
    })

    const splitPriority:Array<any>= adsData  && adsData.length>0 && adsData[0].priority.split(",");
    splitPriority?.map((itemNo:any,index:number)=>{
      if(index===0){
        handlePriority(itemNo);
      }else  if(index===1){
        handlePriority(itemNo);
      }else  if(index===2){
        handlePriority(itemNo);
      }
    })
  };

  React.useEffect(()=>{
    adsData && adsData.length>0 && handleAds();
  },[])
    return (
        <React.Fragment>
            <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
              {products.map((element: any, index: number) => (
                <>
                 { index%dataInterval=== 0 && priorityAds.length>0 && <Grid item xs={12} sm={12} md={6} lg={6} key={index+"hello"}>
                 <AdsSolution adsList={priorityAds} customAdIdList={customAdIdList.length>0 ?customAdIdList :customAdIdListTempo} contactUsAdsBanner={contactUsBanner} flag="2"/>
                 </Grid>}
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <StoreProducts product={element} affiliate_count={affiliate_count} />
                </Grid> 
                </>
              ))}
            </Grid>
            <Grid item style={{ marginTop: '20px' }}>
              {totalPages > 1 ? (
                <Pagination variant="outlined" shape="rounded" count={totalPages} page={page} onChange={pageChange} />
              ) : null}
            </Grid>
          </Grid>
        </React.Fragment>)
}
export default ProductList