import * as React from 'react'
//@ts-ignore
import { Grid } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import StoreProducts from "./StoreProducts.web";
import CustomAds from './CustomAds.web';
import GoogleAdsenseIntegration from '../../blocks/GoogleAdsenseIntegration/src/GoogleAdsenseIntegration.web';
import AdsSolution from './AdsSolution';
import Tooltip from "@material-ui/core/Tooltip";
import { list, offline, download, noData } from "../../blocks/filteritems/src/assets";
export const db = require("../../blocks/filteritems/src/db.json");
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
    // Customizable Area Start
    responseCourses: any[]
    adsAllData:any[]
    getId:any,
    propCo:any,
    isOfflineV:any,
    downloadingDataV:any,
    makeDownloadCOuntV:(courseId: any, isDOwnload: boolean) => boolean;
    // Customizable Area End
}

const CourseListAds: React.FC<Props> = ({ responseCourses = [],adsAllData,getId,propCo,isOfflineV,downloadingDataV,makeDownloadCOuntV}) => {
 const [courseInterval,setcourseInterval]=React.useState(0)
 const [adsList,setAdList]=React.useState<string[]>([]);
 const [priorityAds,setPriorityAds]=React.useState<string[]>([]);
 const [customAdIdList,setcustomAdIdList]=React.useState<any[]>([]);
 const [contactUsBanner,setcontactUsBanner]=React.useState<string>("https://nebulacloud.ai/assets/room/contact-us-banner-9b1204950d03aa3bd00cf7deca317ce5862ddec6ffa25719b50be9c0e605cbf1.jpg");
 const customAdIdListTempo=[
{
    name: "test_3",
    url: "www.test1.com",
    ad_image_url: "https://www.wordstream.com/wp-content/uploads/2021/07/banner-ads-examples-ncino.jpg"
}
]
const handlePriority=(itemNo:any)=>{
  if(itemNo==="1"){
    const countOfGoogleAds = adsList.filter((item) => item === "googleAds").length;
    for (let i = 0; i < countOfGoogleAds; i++) {
      priorityAds.push("googleAds");
    }
  }else if(itemNo==="2"){
    const countOfCustomAds = adsList.filter((item) => item === "customAds").length;
    for (let i = 0; i < countOfCustomAds; i++) {
      priorityAds.push("customAds");
    }
  }else if(itemNo==="3"){
    const countOfThree = adsList.filter((item) => item === "contactUsAds").length;
    for (let i = 0; i < countOfThree; i++) {
      priorityAds.push("contactUsAds");
    }}
}
  const handleAds = () => {

    const splitRatio :Array<any>= adsAllData && adsAllData.length>0 && adsAllData[0].ratio.split(":");
    setcourseInterval(adsAllData && adsAllData.length>0 && adsAllData[0].web_ad_display_interval)
    setcustomAdIdList(adsAllData && adsAllData.length>0 && adsAllData[0].custom_ads);
    setcontactUsBanner(adsAllData && adsAllData.length>0 && adsAllData[0].contact_us_ads.length>0 ?adsAllData[0].contact_us_ads[0]?.image_url : "https://nebulacloud.ai/assets/room/contact-us-banner-9b1204950d03aa3bd00cf7deca317ce5862ddec6ffa25719b50be9c0e605cbf1.jpg");
    splitRatio?.map((itemNo:any,index:number)=>{
      if(index===0){
        for (let gAds: number = 0; gAds < +itemNo; gAds++){
          adsList.push("googleAds")
      }
      }
      if(index===1){
        for (let cAds: number = 0; cAds < +itemNo; cAds++){
          adsList.push("customAds")
        }
      }
      if(index===2){
        for (let coAds: number = 0; coAds < +itemNo; coAds++){
            adsList.push("contactUsAds")
        }
      }
    })

    const splitPriority:Array<any>= adsAllData  && adsAllData.length>0 && adsAllData[0].priority.split(",");
    splitPriority?.map((itemNo:any,index:number)=>{
      if(index===0){
        handlePriority(itemNo);
      }else  if(index===1){
        handlePriority(itemNo);
      }else  if(index===2){
        handlePriority(itemNo);
      }
    })
  };

  React.useEffect(()=>{
    adsAllData && adsAllData.length>0 && handleAds();
  },[])
    return (
        <React.Fragment>
          <Grid xs={12} className="courseContainer">
                            {responseCourses?.map(
                              (course: any, index: number) => {
                                if (course?.id && course?.duration) {
                                  return (
                                    <div id={getId(index, course)}> 
                        { index%courseInterval === 0  &&  <AdsSolution adsList={priorityAds} customAdIdList={customAdIdList} contactUsAdsBanner={contactUsBanner} flag="1" />}
                                      <div
                                        style={{ cursor: "pointer" }}
                                        id="main-div-list-course "
                                        className="main-div-list-course"
                                      >
                                        <div
                                          className="div-course"
                                          key={index + "div"}
                                        >
                                          <img src={list} />
                                          <div
                                            id="navigation-of-course"
                                            onClick={() => {
                                                propCo.navigation.navigate("Course", { id: course.id });
                                            }}
                                          >
                                            <div className="div-identity-sub">
                                              <h1 className="identity-line">
                                                {course?.title}
                                              </h1>
                                            </div>
                                            <div className="sub-div-identity">
                                              <h1 className="duration">
                                                Duration: {course.duration}
                                              </h1>
                                              &nbsp;
                                              {course.new_course && (
                                                <h2>New</h2>
                                              )}
                                              {course?.c_img_urls?.map(
                                                (url: string) => {
                                                  return <img src={url} />;
                                                }
                                              )}
                                            </div>
                                          </div>
                                          <Tooltip
                                            title={
                                              course.offline_downloaded
                                                ?   isOfflineV
                                                  ? "Delete Offline Downloded"
                                                  : "Go Online To Delete"
                                                : "Download To Access Offline"
                                            }
                                            id='tooltips'
                                          >
                                            {downloadingDataV ==
                                              course.id ? (
                                              <div className="loader-class-course">
                                                <CircularProgress />
                                              </div>
                                            ) : (
                                              <img
                                                src={
                                                  course.offline_downloaded
                                                    ? offline
                                                    : download
                                                }
                                                alt=""
                                                style={
                                                  course.offline_downloaded ? {
                                                    filter:
                                                      "invert(76%) sepia(30%) saturate(3461%) hue-rotate(384deg) brightness(92%) contrast(91%)",
                                                  } : {}
                                                }
                                                //@ts-ignore
                                                onClick={() => {
                                                  if (
                                                    !course.offline_downloaded
                                                  ) {
                                                    // this.handleDownloadFunction(this.state?.responseCourses[this.state.dec.indexOf(item) ])
                                                    makeDownloadCOuntV(
                                                      course,
                                                      true
                                                    );
                                                  } else {
                                                    isOfflineV &&
                                                    makeDownloadCOuntV(
                                                        course,
                                                        false
                                                      );
                                                    // this.handleDeleteDownloadFunction(this.state?.responseCourses[this.state.dec.indexOf(item) ])
                                                  }
                                                }}
                                                className="download-icon"
                                              />
                                            )}
                                          </Tooltip>
                                        </div>
                                        <hr key={index} />
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return <></>;
                                }
                              }
                            )}
                          </Grid>
        </React.Fragment>)
}
export default CourseListAds
// Customizable Area End
