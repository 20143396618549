import React from 'react';
import CustomAds from './CustomAds.web';
import GoogleAdsenseIntegration from '../../blocks/GoogleAdsenseIntegration/src/GoogleAdsenseIntegration';
import ContactUsAds from './ContactUsAds';
import { makeStyles } from '@material-ui/core/styles';
import { Card,CardActionArea, CardMedia} from "@material-ui/core";
import Link from '@material-ui/core/Link';
import { CustomAdsCounts } from './CustomAdsCounts';
export const configJSON = require("../../../packages/framework/src/config");
const useStyles = makeStyles({
  media: {
    height: 150,
  },
});
interface AdSolutionProps {
  adsList: any[]; 
  customAdIdList: any[];
  contactUsAdsBanner:string;
  flag:string;
}
const AdsSolution: React.FC<AdSolutionProps> = ({adsList,customAdIdList,contactUsAdsBanner,flag}) => {
  
  const classes = useStyles();
  const handleClick = () => {
    CustomAdsCounts();
  console.log("hello")
  };
  const popAddType = adsList[0];
  adsList.reverse();
  adsList.pop();
  adsList.reverse();
  adsList.push(popAddType);

  if (popAddType === "customAds") {
    const poppedIndex = customAdIdList[0];
    customAdIdList.reverse();
    customAdIdList.pop();
    customAdIdList.reverse();
    customAdIdList.push(poppedIndex);

    return <div style={{padding:"15px"}}>{
     <Link onClick={handleClick} target="_blank" href={poppedIndex.url} rel="noreferrer"> <Card >
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={poppedIndex.ad_image_url}
          title={poppedIndex.name}
        />
      </CardActionArea>
    </Card>  </Link>
  
     }</div>  
  } else if (popAddType === "googleAds") {
    return <GoogleAdsenseIntegration navigation={undefined} id={""} />;
  } else if (popAddType === "contactUsAds") {
    return <ContactUsAds banner={contactUsAdsBanner}/>;
  }
  return null;
};

export default AdsSolution;
